const nb = {
  error: {
    unknownError: 'En feil har oppstått. Forsøk igjen senere.',
  },
  heading: {
    entrances: 'Områder',
    tasks: 'Oppgavevarsler',
    dashboard: 'Dashboard',
    info: 'Informasjon og kundeservice',
    serviceChange: 'Tjenesteendringer'
  },
  resources: {
    booking: 'Bestilling',
    orderManagement: 'Order Management',
    airAndSea: 'Air and Sea',
    calculator: 'Kalkulator',
    tracking: 'Sporing',
    reports: 'Rapporter',
    invoices: 'Fakturaer',
    docSearch: 'Dokumentsøk',
    supportCases: 'Kundeservice',
    useradminExternal: 'Administrasjon',
    customerSearch: 'Customer Search',
    warehousing: 'Warehousing',
    incoming: 'Innkommende sendinger',
    widget: 'Widgetinnstillinger',
    checkout: 'Checkout',
    marketing: 'Markedsføring',
    noAccess: 'Du har ikke tilgang til',
    getAccess: 'Du kan søke om tilgang og rettigheter via <0>din profilside</0>'
  },
  entrances: {
    noShow: 'Ingen innganger vises, legg til noen ved å klikke',
    showTile: 'Vis inngang',
    move: 'Flytt',
    left: 'mot venstre',
    right: 'mot høyre',
    hide: 'Skjul',
    editEntrances: 'Rediger innganger',
    closeConfiguration: 'Lukk redigering',
    configInfo: 'Du kan endre rekkefølgen ved å bruke dra-og-slipp eller knappene på hver inngang.',
    hiddenTilesHeading: 'Skjulte innganger',
    hiddenTilesInfo: 'Dersom du har valgt å skjule noen innganger vil de bli listet opp her. Du kan alltid vise dem igjen.',
    disabledAccessHeading: 'Deaktivert tilgang'
  },
  customerService: {
    label: 'Kontakt kundeservice',
    openingHours: 'Mandag–fredag, ',
    keypad: 'Mybring: tastevalg ',
    trackingKeypad: 'Sporing: tastevalg ',
    then: ' deretter ',
    email: 'E-post',
    phone: 'Telefon',
    openCase: 'Opprett ny sak i',
    guides: 'Mybring- og API-hjelp',
    mybringInfo: 'Få hjelp til Mybring på våre',
    mybringInfoLinkText: 'rådgivningsider',
    mybringInfoLink: 'https://www.bring.no/radgivning/mybring',
    apiInfo: 'Les om våre API-er og hvordan du kan integrere deg mot dem i',
    apiInfoLink: 'Bring API-dokumentasjonen',
    apiUpdatesHeading: 'Abonner på API oppdateringer',
    apiUpdatesText: 'Abonner på <0>API oppdateringer</0> fra Bring.',
    apiUpdatesButton: 'Registrer e-post',
    feedback: 'Tilbakemelding',
    feedbackHead: 'Hjelp oss å forbedre Mybring',
    feedbackInfo: 'Har du innspill til hvordan vi kan gjøre Mybring bedre? Gi tilbakemelding direkte til utviklingsteamene.',
    feedbackForm: 'Åpne skjema',
  },
  revisedServices: {
    description: 'Noen av dine kundenummer har fått tjenester med nye navn og prismodeller.',
    link: 'Informasjon om endringene',
  },
  oebsW3: {
    apiLabel: 'Endringer i API',
    webLabel: 'Endringer på web',
    intro: 'Fra 02. mai 2023 bytter vi til et nytt og forbedret faktureringssystem for våre Home Delivery-tjenester i Norge og Danmark samt regionale budtjenester i Finland. Nedenfor er en oversikt over endringene du vil se på ulike steder i Mybring og våre API-er.',
    api: {
      title: 'Endringer i Mybrings APIer som følge av nytt faktureringssystem',
      intro:
        'Fra 02. mai 2023 bytter vi til et nytt og forbedret faktureringssystem for våre Home Delivery-tjenester i/til Norge og Danmark samt regionale budtjenester i Finland. Nedenfor er en oversikt over endringene som gjelder for deg som er API-kunde.',
      customernumbers: 'Dine API-kundenummer.',
      sg: {
        label: 'Shipping Guide API',
        info1:
          'Spørringer på utgående Home Delivery-tjenestene i/til Norge og Danmark, Home Delivery-returtjenester i/fra Norge og Danmark samt regionale budtjenester i Finland skal gjøres med hovedkundenummer. Du må selv endre til ditt nye API-hovedkundenummer for slike spørringer. Ditt API-hovedkundenummer er identisk med din bedrifts hovedkundenummer. ',
        info2: 'Ditt gamle API-kundenummer vil fortsatt fungere i en overgangsperiode. Vi anbefaler imidlertid at du ',
        link: 'endrer API-kundenummeret som benyttes til spørringer på nevnte tjenester',
        text: ' så raskt som mulig for å unngå problemer senere.',
      },
      booking: {
        label: 'Booking API',
        info1:
          'Bestilling av Home Delivery-tjenestene i/til Norge og Danmark som gjøres via Booking API skal gjøres med hovedkundenummer. Dette gjelder både utgående Home Delivery-tjenester i/til Norge og Danmark og Home Delivery-returtjenester i/fra Norge og Danmark.',
        info2: 'Du må selv endre til ditt nye API-hovedkundenummer for slike bestillinger. Ditt API-hovedkundenummer er identisk med din bedrifts hovedkundenummer. ',
        info3: 'Ditt gamle API-kundenummer vil fortsatt fungere i en overgangsperiode. Vi anbefaler imidlertid at du ',
        link: 'endrer API-kundenummeret som benyttes til bestilling av nevnte tjenester',
        text: ' så raskt som mulig for å unngå problemer senere.',
      },
      invoices: {
        label: 'Invoice API',
        info1:
          'Fakturaer skal tas ut med hovedkundenummer. Ditt API-hovedkundenummer er identisk med din bedrifts hovedkundenummer. ',
        info2: 'Ditt gamle API-kundenummer vil fortsatt fungere i en overgangsperiode. Vi anbefaler imidlertid at du ',
        link: 'endrer API-kundenummeret som benyttes til å ta ut fakturaer',
        text: ' så raskt som mulig for å unngå problemer senere.',
      },
      demosg: {
        label: 'Demo Shipping Guide',
        info: 'Etter bytte til nytt faktureringssystem, vil du finne Home Delivery-tjenester under fanen "Revised services".',
      },
      web: {
        link: 'Oversikt over endringene i Mybrings webportal',
      },
    },
    web: {
      title: 'Endringer i Mybring som følge av nytt faktureringssystem',
      intro:
        'Fra 02. mai 2023 bytter vi til et nytt og forbedret faktureringssystem for våre Home Delivery-tjenester i Norge og Danmark samt regionale budtjenester i Finland. Nedenfor er en oversikt over endringene du vil se på ulike steder i Mybring.',
      booking: {
        label: 'Bestilling',
        new: 'Ny bestilling',
        info: 'Bestilling av Home Delivery-tjenester i/til Norge og Danmark skal gjøres med hovedkundenummer. Dette gjelder både utgående Home Delivery-tjenester i/til Norge og Danmark under "Sende til én" og Home Delivery-returtjenester i/fra Norge og Danmark under "Retur fra én". Kundevelgeren vil automatisk endres til å vise hovedkundenummer. For bestilling av regionale budtjenester i Finland vil kundevelgeren være uendret.',
      },
      pickup: {
        new: 'Ny henting',
        info: 'Bestilling av henting for Home Delivery-sendinger i Norge skal gjøres med hovedkundenummer. Kundevelgeren vil automatisk endres til å vise hovedkundenummer.',
      },
      orderhistory: {
        label: 'Bestillingshistorikk',
        info: 'Alle bestillinger av Home Delivery-tjenester uavhengig av land som gjøres etter bytte til nytt faktureringssystem vil vises med hovedkundenummer. Det samme gjelder bestilte hentinger av Home Delivery-sendinger i Norge. Bestillinger av regionale budtjenester i Finland vil vises med samme kundenummer som tidligere.',
      },
      tracking: {
        label: 'Sporing',
        info: 'Her er det ingen endringer. Både gammelt kundenummer og hovedkundenummer vises som før.',
      },
      invoices: {
        label: 'Fakturaer',
        info1:
          'Faktura på alle bestillinger av Home Delivery-tjenester samt regionale budtjenester i Finland etter bytte til nytt faktureringssystem vil komme under hovedkundenummer.',
        info2:
          'På fakturaer under hovedkundenummer vil du få mer informasjon om din faktura enn det du får i dag, slik som:',
        info3: {
          type: 'Fakturatype',
          source: 'Fakturakilde',
          amount: 'Fakturabeløp',
          currency: 'Valuta',
          text1: 'Hvilken opprinnelig faktura en kreditnota er knyttet mot',
          text2: 'Om fakturaen er betalt eller ikke',
        },
        info4:
          'Du vil se noen kolonner i kolonnevelgeren for Spesifisert fakturarapport (dvs. fakturaspesifikasjonen) som ikke vil ha innhold. Årsaken til denne utvidelsen er at Spesifisert fakturarapport blir en felles rapport for alle kunder, og noen kolonner er bare gyldige for enkelte tjenesteområder.',
        info5:
          'Det er mulig å opprette abonnement på Spesifisert fakturarapport. Abonnementet opprettes på hovedkundenummeret.',
      },
      customerService: {
        label: 'Kundeservice',
        info: 'Her er det ingen endringer. Du melder saker på hovedkundenummer som før.',
      },
      api: {
        label: 'API-endringer',
        info: 'Du må endre til ditt nye API-hovedkundenummer ved bruk av enkelte Mybring APIer.',
        link: 'Les mer',
      },
    },
  },
  missingPhone: {
    heading: 'Manglende telefonnummer',
    text: 'Vi har ikke registrert et telefonnummer på din konto. Dette er nødvendig for å dra full nytte av Mybring-tjenestene (f.eks. motta SMS-er). For å oppdatere ditt telefonnummer ber vi deg gå til ',
    link: 'siden for oppdatering av din profil.',
  },
  dashboard: {
    select: 'Velg kundenummer',
    title: 'Planlagte utleveringer',
    yesterday: 'I går',
    today: 'I dag',
    tomorrow: 'I morgen',
    moredays: 'Flere dager',
    error: 'Vi har problem med å vise informasjonen. Prøv igjen senere.',
    loading: 'Laster',
    remaining: 'Underveis',
    loaded: 'Lastet på bil',
    attempted: 'Forsøkt utlevert',
    pickup: 'På hentested',
    delivered: 'Utlevert',
  },
  overview: {
    titleVol: 'Fakturerte volum',
    titleCost: ' og kostnader',
    loading: 'Laster',
    error: 'Vi har problem med å vise informasjonen. Prøv igjen senere.',
    menu: {
      thisyear: 'Året så langt',
      thismonth: 'Måneden så langt',
      last12month: 'Siste 12 måneder',
      last24month: 'Siste 24 måneder',
    },
    category: 'Sendinger',
    info: 'Ingen data tilgjengelig for denne kunden',
  },
  welcome: {
    skip: 'Hopp til innhold',
    login: 'Logg inn',
    forexisting: 'For eksisterende brukere',
    register1: 'Registrer ny bedrift',
    register2: 'Få et kundenummer og en bruker for innlogging',
    register3: 'Opprett flere brukere',
    register4: 'Når bedriften allerede har et kundenummer',
    features: {
      heading: 'Få oversikt over logistikken med Mybring',
      booking: 'Bestilling',
      bookingIntro: 'Bestill tjenester som inngår i din avtale. Send til én og én eller til mange mottakere samtidig.',
      tracking: 'Sporing',
      trackingIntro: 'Gir avsender utvidet informasjon samt tilgang til signatur og endringstjenester.',
      reports: 'Rapporter',
      reportsIntro: 'Få oversikt over status, leveringskvalitet, avvik, kostnader og klimaavtrykk.',
      invoices: 'Fakturaer',
      invoicesIntro: 'Få oversikt over dine fakturaer i et arkiv med betalingsfrist og -status.',
      supportcases: 'Kundeservice',
      supportcasesIntro:
        'Se alle registrerte saker samt sakslogg og siste status, og opprett nye saker direkte i Mybring.',
      warehousing: 'Warehousing',
      warehousingIntro:
        'Ha full kontroll over ditt varelager. Få oversikt over dine salgs- og innkjøpsordre og se siste status.',
      useradmin: 'Administrasjon',
      useradminIntro: 'Som administrator har du full kontroll på bedriftens kundenummer og Mybring-brukere.',
      link: 'Les mer om Mybring',
    },
    customerservice: {
      label: 'Kundeservice',
      no: 'Bring Norge',
      se: 'Bring Sverige',
      dk: 'Bring Danmark',
      fi: 'Bring Finland',
    },
    terms: 'Vilkår',
    download: 'Last ned',
    policy: 'Personvern og sikkerhet',
    cookies: 'Cookies',
  },
  agreementConversion: {
    read: 'Les mer.',
    here: 'her',
    notAuthorized:
      'The logged-in user is not authorized to access this page. If you have access to the captioned service, but unable to access this page, please contact customer service.',
    infoPage: {
      header: 'Endringer i Mybring knyttet til nye tjenestenavn',
      description: {
        para1:
          'Bring reviderer sin tjenesteportefølje. Noen av våre tjenester har fått nye navn, tjenestekoder og prismodeller.',
        para2:
          'Fra en gitt dato vil din bedrift konverteres til tjenester med nye navn (reviderte tjenester). Når minst ett kundenummer tilknyttet din bruker har blitt konvertert, skal du bestille reviderte tjenester. Dette gjøres ved å bruke din bedrifts hovedkundenummer. Nedenfor er en oversikt over endringene du vil se på ulike steder i Mybring.',
        para3: 'Les mer om de ',
        link: 'reviderte tjenestene',
        text: '.',
      },
      api: {
        header: 'API-endringer',
        text: 'For reviderte tjenester bruker du nye tjenestekoder og ditt hovedkundenummer.',
        introduction: 'Changes in API related to conversion',
        bullets: {
          1: 'For å requeste reviderte tjenester via våre API-er bruker du ditt nye API-kundenummer, som er identisk med din bedrifts hovedkundeummer. For å lese mer om reviderte tjenester i våre API-er, klikk ',
          2: 'For å se dine API-kundenummer, klikk ',
          3: 'I en begrenset periode vil eventuelle bestillinger av gamle tjenester gjort med gamle API-kundenummer automatisk mappes om til ',
          link: 'korresponderende',
          text: ' reviderte tjeneste.',
          4: 'Du kan ikke requeste gamle tjenester med ditt nye API-kundenummer eller reviderte tjenester med ditt gamle API-kundenummer.',
        },
      },
      booking: {
        header: 'Bestilling',
        order: 'Ny bestilling',
        text: 'Du kan begynne å bestille reviderte tjenester når ett kundenummer tilknyttet din bruker har blitt konvertert.',
        heading: {
          text1:
            'For å sjekke hvilke tjenester du kan bestille med gamle kundenummer og hvilke tjenester du kan bestille med hovedkundenummer, klikk ',
          text2:
            'For tjenester med nye tjenestenavn er API-requesten uforandret, bortsett fra måten å requeste tilleggstjenester på. For gamle tjenester kan både gammel og ny måte brukes. Det anbefales imidlertid å ta i bruk den nye måten for alle tjenester da den gamle måten å requeste tilleggstjenester på vil fases ut.',
        },
        table: {
          row: {
            BPAKKE: 'Bedriftspakke',
            EKSPRESS09: 'Bedriftspakke ekspress over natten',
            CARGO: 'Stykk- og partigods*',
            SERVICEPAKKE: 'Klimanøytral Servicepakke',
            PA_DOREN: 'På Døren',
            BPAKKE_RETURSERVICE: 'Bedriftspakke returservice',
            EKSPRESS09_RETURSERVICE: 'Bedriftspakke ekspress over natten returservice',
            SERVICEPAKKE_RETURSERVICE: 'Servicepakke returservice',
            5000: 'Pakke til bedrift',
            4850: 'Ekspress neste dag',
            5100: 'Stykkgods til bedrift',
            5300: 'Partigods til bedrift',
            5800: 'Pakke til hentested',
            5600: 'Pakke levert hjem',
            9000: 'Retur pakke fra bedrift',
            9600: 'Returekspress',
            9100: 'Retur stykkgods fra bedrift',
            9300: 'Retur fra hentested',
          },
        },
        text1:
          'Dersom du forsøker å gå til gamle tjenester ved å bruke bokmerker o.l. etter din konverteringsdato, vil du tas tilbake til hovedsiden for bestilling.',
        text2:
          'For reviderte tjenester er eAdvising ikke lenger en tilleggstjeneste: den er nå inkludert i tjenesten og dens basispris. Varsling per brev kan bestilles mot et tillegg (mulig ved bestilling av utgående sendinger).',
        flow3: 'Pakke i postkassen',
        oldImage: 'Eksempel – Pakke i postkassen kundevelger før konvertering:',
        newImage:
          'Eksempel – Pakke i postkassen kundevelger etter konvertering. Bring Company1 AS og Bring Company3 er konvertert/hovedkundenummer. De andre har ikke blitt konvertert enda.',
      },
      pickup: {
        header: 'Ny henting',
        text: 'For hentinger i Norge vil kundevelgeren endres når du har minst ett konvertert kundenummer tilknyttet din bruker.',
        text1:
          'Kundevelgeren for hentinger i Norge vil endres når du har minst ett konvertert kundenummer tilknyttet din bruker. Fra det tidspunktet vil kundevelgeren vise minst ett hovedkundenummer for fakturering. For hentinger i Sverige og Danmark er det ingen endringer i kundevelgeren – den vil fortsette å vise kun gamle kundenummer.',
        text2:
          'Ikke alle organisasjoner konverteres samtidig. Dersom du har flere organisasjoners kundenummer tilknyttet din bruker, ser du muligens både gamle og hovedkundenummer i kundevelgeren.',
        oldImage: 'Eksempel – Kundevelger for henting før konvertering:',
        newImage:
          'Eksempel – Kundevelger for henting etter konvertering. Bring Company1 AS og Bring Company3 AS er konvertert/hovedkundenummer. De andre har ikke blitt konvertert enda:',
      },
      orderHistory: {
        header: 'Bestillingshistorikk',
        text: 'Alle bestillinger reflekteres i bestillingshistorikken. Bestillinger av gamle tjenester vil vise gammelt kundenummer. Bestillinger av reviderte tjenester vil vise hovedkundenummer.',
      },
      addressbook: {
        header: 'Adressebok',
        text: 'Når ett kundenummer tilknyttet din bruker har blitt konvertert, vil alle kontakter lagret på det gamle kundenummeret migreres over til hovedkundenummeret.',
        list: {
          text1:
            'Når ett kundenummer tilknyttet din bruker har blitt konvertert, vil alle kontakter lagret på det gamle kundenummeret migreres over til hovedkundenummeret. På samme måte vil alle kontakter lagret på gamle kundenummer som ikke er konvertert enda forbli på det gamle kundenummeret.',
          text2:
            'Ikke alle organisasjoner konverteres samtidig. Dersom du har flere organisasjoners kundenummer tilknyttet din bruker, ser du muligens både gamle og hovedkundenummer i kundevelgeren.',
          text3: 'Nye kontakter kan legges til både gamle og hovedkundenummer.',
        },
        image: {
          oldText: 'Eksempel – Kundevelger for adressebok før konvertering:',
          newText:
            'Eksempel – Kundevelger for adressebok etter konvertering. Bring Company1 AS (1000-12345678) og Bring Company1 AS (00011111111) er nå konvertert og slått sammen under hovedkundenummeret Bring Company1 AS (11111111). De andre har ikke blitt konvertert enda:',
        },
      },
      shoppingCart: {
        header: 'Handlekurv',
        text: 'Eventuelle linjer i handlekurven som gjelder gamle tjenester blir automatisk slettet når ett kundenummer tilknyttet din bruker har blitt konvertert.',
        oldImage: 'Eksempel – Handlekurv før konvertering (inkludert en gammel tjeneste):',
        newImage: 'Eksempel – Handlekurv etter konvertering (den gamle tjenesten har blitt slettet):',
        text1:
          'Når minst ett kundenummer tilknyttet din bruker har blitt konvertert kan du kun bestille reviderte tjenester. Dersom du hadde linjer i din handlekurv som gjaldt gamle tjenester har de automatisk blitt slettet etter konverteringen. Handlekurven din vil i så fall gi deg beskjed om dette.',
        text2:
          'Endringen gjelder kun gruppen av tjenester som omfattes av konverteringen. Dersom du hadde linjer i din handlekurv som gjelder andre tjenester forblir de i handlekurven etter konvertering.',
      },
      favourites: {
        header: 'Favoritter',
        text: 'Eventuelle linjer blant dine favoritter som gjelder gamle tjenester blir automatisk deaktivert for bestilling når ett kundenummer tilknyttet din bruker har blitt konvertert.',
        oldImage: 'Eksempel – Favoritter før konvertering (inkludert en gammel tjeneste):',
        newImage: 'Eksempel – Favoritter etter konvertering (den gamle tjenesten har blitt deaktivert for bestilling):',
        text1:
          'Når minst ett kundenummer tilknyttet din bruker har blitt konvertert kan du kun bestille reviderte tjenester. Dersom du har favoritter som gjelder gamle tjenester har de automatisk blitt deaktivert for bestilling etter konvertering. Favoritter-siden vil i så fall gi deg beskjed om dette.',
        text2:
          'Endringen gjelder kun gruppen av tjenester som omfattes av konverteringen. Dersom du hadde linjer blant dine favoritter som gjelder andre tjenester forblir de aktive for bestilling etter konvertering.',
        text3:
          'For å lagre den korresponderende reviderte tjenesten som favoritt, velg âLagre som favorittâ på bekreftelsessiden neste gang du bestiller tjenesten.',
      },
      returnAdmin: {
        header: 'Returadmin',
        text: 'Når et kundenummer har blitt konvertert, vil eventuelle returavtaler for gamle tjenester automatisk migreres til returavtaler for korresponderende reviderte tjenester. Returavtalen for revidert tjeneste vil stå på hovedkundenummeret. Hvis du oppretter en ny returavtale for en revidert tjeneste på et konvertert kundenummer må du bruke hovedkundenummeret når du søker. For nye returavtaler på kundenummer som ikke er konvertert brukes gammelt kundenummer. Det gjelder også nye returavtaler for tjenester utenfor scope for konvertering - selv om kunden er konvertert.',
        text1:
          'Hvilke gamle returtjenester som omfattes av konverteringen og hvilke nye tjenestenavn/-koder de mappes til:',
        table: {
          heading1: 'Gammelt tjenestenavn',
          heading2: 'Gammel tjenestekode',
          heading3: 'Nytt tjenestenavn',
          heading4: 'Ny tjenestekode',
          row1: {
            oldService: {
              text: 'Bedriftspakke returservice',
              code: 'BPAKKE_DOR-DOR_RETURSERVICE (1225)',
            },
            newService: {
              text: 'Retur pakke fra bedrift',
              code: '9000',
            },
          },
          row2: {
            oldService: {
              text: 'Bedriftspakke ekspress over natten returservice',
              code: 'EKSPRESS09_RETURSERVICE (1224)',
            },
            newService: {
              text: 'Returekspress',
              code: '9600',
            },
          },
          row3: {
            oldService: {
              text: 'Servicepakke returservice',
              code: 'SERVICEPAKKE_RETURSERVICE (1207)',
            },
            newService: {
              text: 'Retur fra hentested',
              code: '9300',
            },
          },
        },
        text2: '',
      },
      adminBulksplit: {
        header: 'Bulkadmin',
        text: 'Bulkavtaler gjelder kun tjenester som ikke omfattes av konverteringen. Det skjer ingen endringer med eksisterende bulkavtaler.',
        text1:
          'Bulkavtaler gjelder kun tjenester som ikke omfattes av konverteringen. Det skjer ingen endringer med eksisterende bulkavtaler. Det er mulig å søke opp kunder i bulkadmin ved å bruke enten gamle kundenummer eller hovedkundenummer, avhengig av om kunden er konvertert eller ikke enda. Ved søk på hovedkundenummer vil aktuelle kryssreferanser vises, og brukeren må velge hvilken kryssreferanse den ønsker å opprette en bulkavtale på.',
        imageText: 'Eksempel - søk i bulkadmin for konvertert kunde:',
      },
      calculator: {
        header: 'Kalkulator',
        text: 'Du kan beregne priser og ledetider for reviderte tjenester når ett kundenummer tilknyttet din bruker har blitt konvertert.',
        text1:
          'Kundevelgeren for kalkulatoren vil endres når du har minst ett konvertert kundenummer tilknyttet din bruker. Fra det tidspunktet kan du bruke hovedkundenummeret til å beregne priser og ledetider for de reviderte tjenestene Stykkgods til bedrift og Partigods til bedrift.',
        text2:
          'Ikke alle organisasjoner konverteres samtidig. Dersom du har flere organisasjoners kundenummer tilknyttet din bruker, ser du muligens både gamle og hovedkundenummer i kundevelgeren. I så fall kan du fortsatt beregne priser og ledetider for den gamle tjenesten Stykk- og partigods ved å bruke et av de gamle kundenummerene i kundevelgeren.',
        text3:
          'Den gamle tjenesten Stykk- og partigods er splittet i to reviderte tjenester: Stykkgods til bedrift og Partigods til bedrift. Du må derfor velge hvilken tjeneste du ønsker å beregne for når du bruker kalkulatoren med ditt hovedkundenummer.',
        oldImage: 'Eksempel – Kalkulator før konvertering (gammelt kundenummer/gammel tjeneste):',
        newImage: 'Eksempel – Kalkulator etter konvertering (hovedkundenummer/reviderte tjenester):',
      },
      tracking: {
        header: 'Sporing',
        text: 'Her er det ingen endringer. Vi viser både gammelt kundenummer og hovedkundenummer som før.',
      },
      reports: {
        header: 'Rapporter',
        text: 'I kundevelgeren vil gammelt kundenummer skjules etter konvertering, med unntak av Bring Parcels AB.',
        subheading: 'Rapporter og abonnementer',
        text1:
          'Rapporter tas ut på hovedkundenummer, og vil kunne omfatte flere gamle kundenumre i valgt tjenesteområde. Dersom du tidligere hadde 3 ulike kundenummer for stykk- og partigods som nå er samlet under samme hovedkundenummer, vil disse komme i en samlet rapport.',
        text2: 'Kolonnene i rapporter beholdes og både gamle og nye tjenester inkluderes i samme rapport.',
        heading: 'Rapportabonnement',
        text3:
          'Vi flytter abonnement fra gamle kundenummer til hovedkundenummer, med samme innhold. Eieren av abonnement får e-post om når dette skjer. Dersom du har hatt flere abonnement på ulike kundenumre som nå er samlet under samme hovedkundenummer, vil du kunne ende opp med duplikate abonnementer. Du må i så fall gå inn på Rapporter-Abonnement for å fjerne de du ikke lenger har behov for.',
        oldImage: 'Eksempel på gammel og ny kundevelger:',
        newImage: 'Abonnementssiden der du kan endre dine abonnement:',
      },
      dashboard: {
        header: 'Dashboard',
        text: 'I kundevelgeren vil gammelt kundenummer skjules etter konvertering.',
      },
      invoices: {
        header: 'Faktura',
        text: 'Når dine kundenummer er konvertert, fjernes de gamle kundenummerne i kundevelgeren. Fakturaer for gamle kundenummere finnes under hovedkundenummeret. Fakturaene er fortsatt synlig i ett år.',
      },
      customerservice: {
        header: 'Kundeservice',
        text: 'Her er det ingen endringer. Du melder saker på hovedkundenummer, som før.',
      },
      orderManagement: {
        header: 'Order Management',
        text: 'For all APIs, we will use new customer number instead of old customer number. Also, all suppliers will be ported to new customer number. For more details',
        text1:
          "Users having customers for which agreement has been migrated to new customer number, will use new customer number for all API's and UI.",
        heading1: {
          label: 'API Changes',
          subheading1: {
            label: 'IsCustomerValidForOrderManagement',
            text: 'For this api new customer number will be used, because for a customer for which agreement has been migrated to new customer all pickup order will be cretaed on new customer number.',
          },
          subheading2: {
            label: 'ListOrderInformation',
            text: 'As order will be created using new customer number, so to list order info new customer number will be used.',
          },
          subheading3: {
            label: 'ListOrderItems',
            text: 'As order will be created using new customer number, so to list order items new customer number will be used.',
          },
          subheading4: {
            label: 'SenderStatusForOrder',
            text: 'User will have to use new customer number to find sender status.',
          },
          subheading5: {
            label: 'ListPackagingListForOrder',
            text: 'Users will have to use new customer number to get packaging list of order as order for agreement converted customers will be created using new customer number.',
          },
          subheading6: {
            label: 'Report API',
            text: 'For all the order for which booking has been done using new customer number, will have to use new customer number to generate all types of reports.',
          },
        },
        heading2: {
          label: 'Mybring UI Changes',
          text1:
            'Users will have to search purchase order using new customer number for converted customer (dropdown will show new customer number for customer having agreement with new customer number, and for customers with old agreement, old customer number will be displayed.)',
          text2:
            'User will have to create supplier using new customer number for any customer that is new to Purchase order',
          text3:
            'User having suppliers on old customer number will automatically changed to supplier with new customer number',
          text4:
            'All suppliers that has to be used in purchase order by a user, should be added to that user using super user profile.',
        },
      },
      shippingguide: {
        header: 'Shipping guide',
        text1: 'Du må oppgradere til ',
        link: 'Shipping Guide 2.0',
        text2:
          ' for å kunne requeste tjenester med nye navn etter konvertering. Shipping Guide 2.0 støtter bade gamle tjenester og tjenester med nye navn, så det anbefales at du oppgraderer så raskt som mulig for å unngå at din integrasjon slutter å fungere.',
      },
      newPickup: {
        header: 'New pickup',
        text: 'Kundevelgeren for hentinger i Norge vil endres når du har minst ett konvertert kundenummer tilknyttet din bruker. Fra det tidspunktet vil kundevelgeren vise minst ett hovedkundenummer for fakturering. For hentinger i Sverige og Danmark er det ingen endringer i kundevelgeren – den vil fortsette å vise kun gamle kundenummer.',
      },
      customerServiceMapping: {
        header: 'Mapping mellom kundenummer og tilgjengelige tjenester',
        text: 'Hvilke kundenummer tilknyttet din bruker som kan brukes til å bestille hvilke tjenester.',
        superuser:
          'Du er innlogget som superbruker. Som superbruker har du ingen kundenummer tilknyttet din bruker, og kundenummer/tjeneste mapping er derfor ikke tilgjengelig for deg.',
        customer: {
          name: 'Navn',
          customerNo: 'Kundenummer',
          code: 'Landskode',
          products: 'Tjenester',
        },
      },
      promotions: {
        text1:
          'Når du foretar et dekningssøk NO -> NO med minst ett konvertert kundenummer tilknyttet din bruker, vil du se reviderte tjenester i stedet for gamle tjenester. Når du bestiller reviderte tjenester bruker du din bedrifts hovedkundenummer – kundevelgeren vil altså vise ditt hovedkundenummer for fakturering. De nevnte endringene gjelder både sende til/retur fra én samt sende til/retur fra flere. ',
        text2:
          'Det er ingen endringer i tjenester som gjelder andre landkombinasjoner, og du vil fortsette å bruke ditt gamle kundenummer for å bestille disse. For å sjekke hvilke tjenester du kan bestille med hvilket kundenummer, klikk ',
        text3:
          'Hvilke gamle tjenester som omfattes av konverteringen og hvilke nye tjenestenavn/-koder de mappes til: ',
        table: {
          heading1: 'Gammelt tjenestenavn',
          heading2: 'Gammel tjenestekode',
          heading3: 'Nytt tjenestenavn',
          heading4: 'Ny tjenestekode',
        },
      },
      cargo:
        '* Det er én-til-én mapping mellom gamle og reviderte tjenester (og deres respektive bestillingsflyter) – bortsett fra den gamle tjenesten Stykk- og partigods. Denne tjenesten hadde én felles bestillingsflyt, men er splittet i to reviderte tjenester/bestillingsflyter: Stykkgods til bedrift og Partigods til bedrift. En separat bestillingsflyt er også laget for den reviderte tjenesten Retur stykkgods fra bedrift (Ny bestilling -> Retur fra én). Den gamle korresponderende tjenesten ble bestilt via bestillingsflyten for Stykk- og partigods ved å velge âReturâ under sendingsdetaljer.',
      coverageSearch: {
        oldImage: 'Dekningssøk NO -> NO før konvertering (gamle tjenester):',
        newImage: 'Dekningssøk NO -> NO etter konvertering (reviderte tjenester):',
      },
      orderToMailbox: {
        text1:
          'Kundevelgeren for Pakke i postkassen vil endres når du har minst ett konvertert kundenummer tilknyttet din bruker. Fra det tidspunktet vil kundevelgeren vise minst ett hovedkundenummer for fakturering.',
        text2:
          'Ikke alle organisasjoner konverteres samtidig. Dersom du har flere organisasjoners kundenummer tilknyttet din bruker, ser du muligens både gamle og hovedkundenummer i kundevelgeren.',
      },
    },
  },
  operationalMessages: {
    backtofrontpage: 'Tilbake til forsiden',
    select: 'Velg land',
    title: 'Driftsmeldinger',
    seemore: 'Flere driftsmeldinger',
    loading: 'Laster',
    country: {
      no: 'Norge',
      se: 'Sverige',
      dk: 'Danmark',
    },
    error: 'Vi har problem med å vise driftsmeldinger. Prøv igjen senere.',
    none: 'Ingen meldinger å vise.',
  },
  notcollectedatpup: {
    label: 'Ikke hentet på hentested',
    onetotwodays: '1-2 dager igjen',
    threetofourdays: '3-4 dager igjen',
    error: 'Vi har problem med å vise informasjonen. Prøv igjen senere.',
  },
  taskCenter: {
    messages: 'Meldinger',
    useradmin:'Administrasjon',
    cases: {
      caseNumber: 'Sak: ',
      status: {
        NEW: 'Ny',
        IN_PROGRESS: 'Under behandling',
        AWAITING_CUSTOMER: 'Venter på kunde',
        CLOSED: 'Avsluttet',
      },
    },
  },
  searchable_select: {
    customerEmptyText: 'Ingen kunder matcher søket ditt...',
    customerPlaceholderText: 'Søk og velg en av dine kunder...',
    listboxLabelCustomer: 'Liste med velgbare kunder',
  },
  bankInfo: {
    header: 'Bankbytte | Dette må du gjøre',
    p1: 'Posten Bring AS med datterselskaper har nylig byttet bank. Ny betalingsinformasjon finnes på faktura. For å sikre betaling av fakturaene og for å unngå forsinkelser må du oppdatere ny betalingsinformasjon i dine økonomissystem.',
    p2: 'Les mer: ',
    ctaLabel: 'Bytte av konsernbank for Posten Bring AS',
    ctaLink: 'https://www.bring.no/kundeservice/bytte-konsernbank'
  },
  checkITContactInfo: {
    header: 'Gratis checkout rådgivning for nettbutikker',
    text: 'Ønsker du hjelp til å optimalisere din checkout? Våre checkout-eksperter sitter klare til å hjelpe deg! Avtal et møte med en rådgiver her: <0>Avtal møte</0> eller ta kontakt med oss på <1>checkout.implementering@posten.no</1>',
    emailLink: 'mailto:checkout.implementering@posten.no',
  },
};
export default nb;
